<template>
  <div class="absolute top-0">
    <div
      class="fixed flex xl:gap-200 lg:gap-150 md:gap-100 h-screen w-full bg-white overflow-auto md:overflow-hidden"
      :class="headerType === 'secondary' ? 'px-6 md:px-0' : ''"
    >
      <div :class="[headerClasses, headerType === 'secondary' ? 'hidden md:block' : '']">
        <img
          src="/content/images/brightbid/brightbid-logo-white.svg"
          alt="Brightbid"
        />
        <div class="get-started font-bold">
          <div class="w-full h-full flex items-center justify-center">
            <div>
              <slot name="header">
                <div>
                  <h1 class="gradient text-5xl mb-0 leading-normal">Get started</h1>
                  <h1 class="mb-0 text-5xl leading-normal text-white">{{ subTitle }}</h1>
                </div>
              </slot>
            </div>
          </div>
        </div>

        <img
          src="/content/images/illustrations/onboarding-rectangles-1.svg"
          :class="['onboarding-rectangles', imageClasses]"
          alt="Brightbid"
        />
        <img
          src="/content/images/illustrations/onboarding-skewed-circles-1.svg"
          :class="['skewed-circles', imageClasses]"
          alt="Brightbid"
        />
      </div>

      <!--Signup form-->
      <div class="h-screen flex items-center">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OnboardingComponent',
  props: {
    headerType: {
      type: String,
      default: 'primary',
    },
    subTitle: {
      type: String,
      default: 'with BrightBid',
    },
  },
  computed: {
    headerClasses() {
      const classes = ['relative', 'header-card']
      return [...classes, this.headerType === 'primary' ? 'purple' : 'black']
    },
    imageClasses() {
      return [this.headerType === 'secondary' ? 'resized' : '']
    },
  },
}
</script>

<style scoped lang="scss">
.gradient {
  background: linear-gradient(167deg, #84fae4 45.93%, #677fea 80.03%, #5369cd 112.9%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header-card {
  width: 635px;
  padding: 100px;
  background: $bb-brand-purple;

  &.purple {
    background: $bb-brand-purple;
  }

  &.black {
    background: $bb-text-headers;
  }
}

.get-started {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding-left: 100px;
  padding-bottom: 50px;
}

.skewed-circles {
  position: absolute;
  bottom: 0;
  right: 25px;

  &.resized {
    @media (max-height: 960px) {
      width: 30%;
    }
  }
}

.onboarding-rectangles {
  position: absolute;
  bottom: 0;

  &.resized {
    @media (max-height: 960px) {
      left: 300px;
      width: 32%;
    }
  }
}
</style>
